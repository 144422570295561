import { isAxiosError } from "axios";

import type { IRequestOptions } from "@/types/utils";

import { client } from "../client";
import type { Machine } from "../machine";
import type { Network, NetworkNode } from "../network";
import type { User } from "../user";
import type {
  MachineNodeByIDApiResponse,
  MachineNodeResponse,
  NodeActiveStatus,
  NodeGeolocationResponse,
  NodeRewardsResponse,
  NodeSyncStatus,
  NodeVersionResponse,
  OperatorApiResponse,
  PendingDeploymentApiResponse,
  PendingSetupNodeResponse,
  UserNetworkApiResponse,
  UserNetworkNodesResponse,
} from "./api-response-type";
import type {
  MachineNode,
  MachineNodeInfo,
  NodeActionType,
  NodeGeolocation,
  OperatorList,
  PendingDeploymentItem,
  PendingSetupNode,
  UserNetwork,
  UserNetworkNode,
  UserNodesStats,
} from "./api-type";
import {
  mapMachineNodeInfoData,
  mapMachineNodeResponse,
  mapOperatorListResponse,
  mapPendingDeploymentResponse,
  mapPendingSetupNodeResponse,
  mapUserNetwork,
  mapUserNetworkNodesResponse,
} from "./mapping";

/**
 * Retrieves the list of operators from the server.
 *
 * @param option - Optional request options.
 * @returns A Promise that resolves to the list of operators.
 */
export const getOperatorList = async (option?: IRequestOptions): Promise<OperatorList> => {
  const endpoint = `node/operators`;
  const response = await client.get<OperatorApiResponse[]>(endpoint, option?.requestConfig);

  return response.data.map(mapOperatorListResponse);
};

/**
 * Retrieves a list of pending deployments.
 *
 * @param option - Optional request options.
 * @returns A promise that resolves to an array of `PendingDeploymentItem` objects.
 */
export const getPendingDeploymentsList = async (option?: IRequestOptions): Promise<PendingDeploymentItem[]> => {
  const endpoint = "/node/pending/deployments";
  const response = await client.get<PendingDeploymentApiResponse[]>(endpoint, option?.requestConfig);

  return response.data.map(mapPendingDeploymentResponse);
};

/**
 * Retrieves the list of network for a given user.
 *
 * @param user - The user object.
 * @returns A Promise that resolves to an array of user network information.
 */
export async function getUserNetwork(user: User | User["id"] | null, options?: IRequestOptions): Promise<UserNetwork[]> {
  let endpoint = "/node/networks";

  if (user != null) {
    const userID = typeof user === "string" ? user : user.id;
    endpoint = `/node/networks/${userID}`;
  }

  const response = await client.get<UserNetworkApiResponse[]>(endpoint, options?.requestConfig);

  return response.data.map(mapUserNetwork);
}

/**
 * Retrieves the nodes associated with a user and a network.
 *
 * @param user - The user object or user ID.
 * @param network - The network object.
 * @param options - Optional request options.
 * @returns A Promise that resolves to the nodes data.
 */
export const getUserNodesByNetwork = async (
  user: User | User["id"] | null,
  network: Network,
  options?: IRequestOptions,
): Promise<UserNetworkNode[]> => {
  let endpoint = `/node/list/network/${network.id}`;

  if (user != null) {
    const userID = typeof user === "string" ? user : user.id;
    endpoint = `/node/list/${userID}/${network.id}`;
  }

  const response = await client.get<UserNetworkNodesResponse[]>(endpoint, options?.requestConfig);
  return response.data.map(mapUserNetworkNodesResponse);
};

/**
 * Sets up a node using the provided form data.
 * @param formData - The form data for setting up the node.
 * @param options - Optional request options.
 */
export const setupNode = async <T>(formData: T, options?: IRequestOptions) => {
  const endpoint = "/node/setup";
  await client.post(endpoint, formData, options?.requestConfig);
};

/**
 * Retrieves the pending nodes for a specific user and network.
 *
 * @param user - The user object or user ID.
 * @param network - The network object.
 * @param options - Optional request options.
 * @returns A promise that resolves to an array of user network nodes.
 */
export const getUserPendingSetupNodesByNetwork = async (
  user: User | User["id"],
  network: Network,
  options?: IRequestOptions,
): Promise<PendingSetupNode[]> => {
  const userID = typeof user === "string" ? user : user.id;
  const endpoint = `/node/pending/deployments/${userID}/${network.id}`;
  const response = await client.get<PendingSetupNodeResponse[]>(endpoint, options?.requestConfig);

  return response.data.map(mapPendingSetupNodeResponse);
};

/**
 * Retrieves the list of nodes hosted on a machine.
 *
 * @param machine - The machine object.
 * @param options - Optional request options.
 * @returns A promise that resolves to an array of MachineNode objects.
 */
export const getNodesHostedOnMachine = async (machine: Machine, options?: IRequestOptions): Promise<MachineNode[]> => {
  const endpoint = `/node/list/machine/${machine.id}`;
  const response = await client.get<MachineNodeResponse[]>(endpoint, options?.requestConfig);

  return response.data.map(mapMachineNodeResponse);
};

/**
 * Checks if a node is synced.
 * @param nodeID - The ID of the node to check.
 * @param options - Optional request options.
 * @returns A promise that resolves to a boolean indicating whether the node is synced or not, or null if the status is unknown.
 */
export const isNodeSynced = async (nodeID: string | NetworkNode, options?: IRequestOptions): Promise<boolean | null> => {
  const id = typeof nodeID === "string" ? nodeID : nodeID.nodeID;
  const endpoint = `/node/${id}/sync-status`;
  const response = await client.get<NodeSyncStatus>(endpoint, options?.requestConfig);

  return response.data.status;
};

/**
 * Checks if a node is active.
 * @param nodeID - The ID of the node to check.
 * @param options - Optional request options.
 * @returns A promise that resolves to a boolean indicating whether the node is active or not, or null if the status is unknown.
 */
export const isNodeActive = async (nodeID: string, options?: IRequestOptions): Promise<boolean | null> => {
  const endpoint = `/node/${nodeID}/active`;
  const response = await client.get<NodeActiveStatus>(endpoint, options?.requestConfig);

  return response.data.status;
};

/**
 * Retrieves the version of a node.
 * @param nodeID - The ID of the node.
 * @param options - Optional request options.
 * @returns A Promise that resolves to the version of the node, or null if the version is not available.
 */
export const getNodeVersion = async (nodeID: string, options?: IRequestOptions): Promise<string | null> => {
  const endpoint = `/node/${nodeID}/version`;
  const response = await client.get<NodeVersionResponse>(endpoint, options?.requestConfig);

  return response.data.version;
};

/**
 * Retrieves the reward for a specific node.
 *
 * @param nodeID - The ID of the node.
 * @param options - Optional request options.
 * @returns A Promise that resolves to a string representing the node reward, or null if the reward is not available.
 */
export const getNodeReward = async (nodeID: string, options?: IRequestOptions): Promise<string | null> => {
  const endpoint = `/node/${nodeID}/rewards`;
  const response = await client.get<NodeRewardsResponse>(endpoint, options?.requestConfig);

  return response.data.rewards;
};

/**
 * Retrieves the geolocation information for a specific node.
 *
 * @param nodeID - The ID of the node.
 * @param options - Optional request options.
 * @returns A promise that resolves to the geolocation information of the node, or null if not found.
 */
export const getNodeGeolocation = async (nodeID: string, options?: IRequestOptions): Promise<NodeGeolocation | null> => {
  try {
    const endpoint = `/node/${nodeID}/geo-location`;
    const response = await client.get<NodeGeolocationResponse>(endpoint, options?.requestConfig);
    return response.data;
  } catch (error) {
    if (isAxiosError(error) && error.response?.status === 404) {
      return null;
    }

    throw error;
  }
};

/**
 * Invokes a node action.
 *
 * @param {string} nodeID - The ID of the node.
 * @param {NodeActionType} action - The type of action to invoke.
 * @param {IRequestOptions} [options] - Optional request options.
 * @returns {Promise<void>} - A promise that resolves when the action is invoked.
 */
export const invokeNodeAction = async (nodeID: string, action: NodeActionType, options?: IRequestOptions): Promise<void> => {
  const endpoint = `/node/${action}`;
  const body = { nodeId: nodeID };
  await client.post(endpoint, body, options?.requestConfig);
};

/**
 * Upgrades all network nodes to the specified version.
 *
 * @param network - The network object.
 * @param version - The version to upgrade to.
 * @param options - Optional request options.
 * @returns A promise that resolves when the upgrade is complete.
 */
export const upgradeNetworkNodes = async (network: Network, version: string, options?: IRequestOptions): Promise<void> => {
  const endpoint = "/node/upgrade/all";
  let sanitizedVersion = version.trim();

  if (!sanitizedVersion.startsWith("v") && sanitizedVersion !== "latest") {
    sanitizedVersion = `v${sanitizedVersion}`;
  }

  const body = { networkId: network.id, version: sanitizedVersion };
  await client.post(endpoint, body, options?.requestConfig);
};

/**
 * Upgrades a machine node to the specified version.
 * @param node - The machine node to upgrade.
 * @param version - The version to upgrade to.
 * @param options - Optional request options.
 * @returns A Promise that resolves when the upgrade is complete.
 */
export const upgradeNode = async (node: string, version: string, options?: IRequestOptions): Promise<void> => {
  const endpoint = "/node/upgrade";
  let sanitizedVersion = version.trim();

  if (!sanitizedVersion.startsWith("v") && sanitizedVersion !== "latest") {
    sanitizedVersion = `v${sanitizedVersion}`;
  }

  const body = { nodeId: node, version };
  await client.post(endpoint, body, options?.requestConfig);
};

/**
 * Retrieves the statistics of user nodes.
 * @param option - Optional request options.
 * @returns A promise that resolves to an object containing the total node count and total network count.
 */
export const getUserNodesStats = async (option?: IRequestOptions): Promise<UserNodesStats> => {
  const endpoint = `/node/basic-stats`;
  const response = await client.get<{
    hostedNetworks: number;
    hostedNodes: number;
  }>(endpoint, option?.requestConfig);

  const { hostedNetworks, hostedNodes } = response.data;
  return { totalNodeCount: hostedNodes, totalNetworkCount: hostedNetworks };
};

export const getNodeByID = async (nodeID: string, options?: IRequestOptions): Promise<MachineNodeInfo | null> => {
  try {
    const endpoint = `/node/${nodeID}`;
    const response = await client.get<MachineNodeByIDApiResponse>(endpoint, options?.requestConfig);
    return mapMachineNodeInfoData(response.data);
  } catch (error) {
    if (isAxiosError(error) && error.response?.status === 404) {
      return null;
    }

    throw error;
  }
};
