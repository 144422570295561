import { isAxiosError } from "axios";

import type { IRequestOptions, UnixSeconds } from "@/types/utils";

import { client } from "../client";
import type { AvailableMachinesResponse, MachineApiResponse, MachineListResponse } from "./api-response";
import type { AvailableMachine, Machine, MachineCreateAttributes, MachineIssueFrequency, MachineList } from "./api-types";
import { mapAvailableMachineResponse, mapMachineListResponse, mapMachineResponse } from "./mapping";

/**
 * Retrieves a list of all machines.
 *
 * @param option - The request options.
 * @returns A promise that resolves to an array of Machine objects.
 */
export const getAllMachine = async (option: IRequestOptions): Promise<MachineList> => {
  const endpoint = "/machine/list";
  const response = await client.get<MachineListResponse[]>(endpoint, option.requestConfig);
  return response.data.map(mapMachineListResponse);
};

/**
 * Retrieves a machine by its ID.
 * @param machineID - The ID of the machine to retrieve.
 * @param option - Optional request options.
 * @returns A promise that resolves to the machine data.
 */
export const getMachineByID = async (machineID: string, option?: IRequestOptions): Promise<Machine | null> => {
  try {
    const endpoint = `/machine/${machineID}`;
    const response = await client.get<MachineApiResponse>(endpoint, option?.requestConfig);
    return mapMachineResponse(response.data);
  } catch (err) {
    if (isAxiosError(err) && err.response?.status === 404) {
      return null;
    }

    throw err;
  }
};

/**
 * Creates a new machine with the provided IP and machine details.
 * @param ip - The IP address of the machine.
 * @param machine - The machine details excluding the ID.
 */
export const createNewMachine = async (machine: MachineCreateAttributes) => {
  const endpoint = "/machine/add";
  const data = {
    ip: machine.ip,
    provider: machine.provider,
    datacenter: machine.datacenter,
    ram: machine.ram.toString(),
    vcpu: machine.vcpu.toString(),
    storage: machine.storage.toString(),
    purchasedAt: machine.purchasedAt,
    cost: machine.cost.toString(),
    bandwidth: machine.bandwidth.toString(),
    currency: machine.currency,
  };

  await client.post(endpoint, data);
};

/**
 * Updates the machine with the provided ID and machine details.
 * @param machine - The machine details including the ID.
 */
export const updateMachine = async (machine: Partial<MachineCreateAttributes> & { id: Machine["id"] }) => {
  const data = {
    provider: machine.provider,
    datacenter: machine.datacenter,
    ram: machine.ram?.toString(),
    vcpu: machine.vcpu?.toString(),
    storage: machine.storage?.toString(),
    purchasedAt: machine.purchasedAt,
    cost: machine.cost?.toString(),
    bandwidth: machine.bandwidth?.toString(),
    currency: machine.currency,
  };
  const endpoint = `/machine/update/${machine.id}`;
  await client.patch(endpoint, data);
};

/**
 * Deletes a machine.
 *
 * @param machine - The machine object to be deleted.
 * @returns A promise that resolves when the machine is successfully deleted.
 */
export const deleteMachine = async (machine: Machine) => {
  const endpoint = `/machine/${machine.id}`;
  await client.delete(endpoint);
};

/**
 * Retrieves the frequency of issues for a specific machine.
 *
 * @param machineID - The ID of the machine.
 * @param unixFrom - The starting timestamp in Unix seconds.
 * @param option - Optional request options.
 * @returns A promise that resolves to the frequency of machine issues.
 */
export const getMachineIssues = async (machine: Machine, unixFrom: UnixSeconds, option?: IRequestOptions): Promise<MachineIssueFrequency> => {
  const endpoint = `/stats/${machine.id}/nodes/issues`;
  const params = {
    ...(option?.requestConfig?.params as Record<string, string> | undefined),
    from: unixFrom,
  };
  const response = await client.get<MachineIssueFrequency>(endpoint, { ...option?.requestConfig, params });

  return response.data;
};

/**
 * Retrieves the list of available machines.
 *
 * @param option - The request options.
 * @returns A Promise that resolves to the response data.
 */
export const getAvailableMachine = async (option?: IRequestOptions): Promise<AvailableMachine[]> => {
  const endpoint = "machine/availability/list";
  const response = await client.get<AvailableMachinesResponse[]>(endpoint, option?.requestConfig);

  return response.data.map(mapAvailableMachineResponse);
};
