import dayjs from "dayjs";

import { mapMachineResponse } from "../machine/mapping";
import { mapNetworkNodePlan, mapToNetwork } from "../network/mapping";
import { mapWhoAmIUserDTO } from "../user/mapping";
import type {
  MachineNodeByIDApiResponse,
  MachineNodeResponse,
  OperatorApiResponse,
  PendingDeploymentApiResponse,
  PendingSetupNodeResponse,
  UserNetworkApiResponse,
  UserNetworkNodesResponse,
} from "./api-response-type";
import type {
  MachineNode,
  MachineNodeInfo,
  OperatorListItem,
  PendingDeploymentItem,
  PendingSetupNode,
  UserNetwork,
  UserNetworkNode,
} from "./api-type";

export const mapOperatorListResponse = (data: OperatorApiResponse): OperatorListItem => ({
  user: mapWhoAmIUserDTO(data.user),
  totalNetwork: data.totalNetworks,
  totalNode: data.totalNodes,
});

export const mapPendingDeploymentResponse = (data: PendingDeploymentApiResponse): PendingDeploymentItem => ({
  id: data.id,
  user: {
    id: data.user._id,
    email: data.user.email,
  },
  network: {
    id: data.network._id,
    name: data.network.displayName,
    symbol: data.network.shortName,
  },
  noOfNodes: data.nodesCount,
  requestedAt: new Date(data.requestedAt),
});

export const mapUserNetworkNodesResponse = (data: UserNetworkNodesResponse): UserNetworkNode => ({
  address: data.address,
  nodeID: data.nodeId,
  isActive: data.isActive,
  runningSince: dayjs.unix(data.runningSince).toDate(),
  staked: data.staked,
  rewards: data.rewards,
});

export const mapPendingSetupNodeResponse = (data: PendingSetupNodeResponse): PendingSetupNode => ({
  machineNodeId: data._id,
  nodeID: data.nodeId,
  nodeAddress: data.nodeAddress,
  nodeIndex: data.nodeIndex,
  nodeType: data.nodeType,
  requestedAt: new Date(data.requestedAt),
});

export const mapMachineNodeResponse = (data: MachineNodeResponse): MachineNode => ({
  user: data.user,
  network: data.network,
  node: {
    address: data.node.nodeAddress,
    nodeID: data.node.id,
    isActive: data.node.isActive,
    runningSince: dayjs.unix(data.node.runningSince).toDate(),
  },
});

export const mapUserNetwork = (data: UserNetworkApiResponse): UserNetwork => ({
  network: mapToNetwork(data.network),
  pending: data.pendingNodes,
  hosted: data.hostedNodes,
  staked: data.staked,
  rewards: data.rewards,
});

export const mapMachineNodeInfoData = (data: MachineNodeByIDApiResponse): MachineNodeInfo => ({
  address: data.nodeAddress,
  createdAt: dayjs(data.createdAt).toDate(),
  deployedAt: data.deployedAt ? dayjs(data.deployedAt).toDate() : null,
  id: data._id,
  machine: mapMachineResponse(data.machineId),
  name: data.name,
  network: mapToNetwork(data.nodeId.networkId),
  user: mapWhoAmIUserDTO(data.userId),
  node: mapNetworkNodePlan({ ...data.nodeId, networkId: data.nodeId.networkId._id }),
  payment: {
    id: data.paymentId,
  },
  nodeIndex: 0,
  status: data.status,
  updatedAt: dayjs(data.updatedAt).toDate(),
});
