import dayjs from "dayjs";

import type { NewCustomerStatusResponse, UserListItemResponse, WhoAmIResponse } from "./api-response-types";
import type { NewConsumerStatus, UserListItem, WhoAmI } from "./api-types";

export const mapWhoAmIUserDTO = (response: WhoAmIResponse): WhoAmI => ({
  name: response.name,
  imageUrl: response.imageUrl,
  role: response.role,
  email: response.email,
  id: response._id,
  phoneNumber: response.phoneNumber,
});

export const mapNewConsumerStatusDTO = (response: NewCustomerStatusResponse): NewConsumerStatus => ({
  id: response.id,
  user: {
    id: response.user._id,
    email: response.user.email,
  },
  network: {
    id: response.network._id,
    name: response.network.displayName,
    symbol: response.network.shortName,
  },
  status: {
    completed: response.status.completed,
    label: response.status.label,
  },
});

export const mapUserListItemDTO = (response: UserListItemResponse): UserListItem => ({
  id: response._id,
  name: response.name,
  email: response.email,
  role: response.role,
  createdAt: dayjs(response.createdAt).toDate(),
});
