import type { AvailableMachinesResponse, MachineApiResponse, MachineListResponse } from "./api-response";
import type { AvailableMachine, Machine, MachineListItem } from "./api-types";

export const mapMachineResponse = (value: MachineApiResponse): Machine => ({
  id: value._id,
  ip: value.ip,
  provider: value.provider,
  datacenter: value.datacenter,
  ram: value.ram,
  vCPU: value.vcpu,
  storage: value.storage,
  purchasedAt: new Date(value.purchasedAt),
  name: value.machineName,
  cost: {
    amount: value.cost,
    currency: value.currency,
  },
  createdAt: new Date(value.createdAt),
  updatedAt: new Date(value.updatedAt),
  status: value.status,
  bandwidth: value.bandwidth,
});

export const mapMachineListResponse = (value: MachineListResponse): MachineListItem => ({
  activeNodes: value.activeNodes,
  inactiveNodes: value.inactiveNodes,
  id: value.id,
  cost: {
    amount: value.cost,
    currency: "USD",
  },
  bandwidth: value.bandwidth,
  name: value.name,
});

export const mapAvailableMachineResponse = (value: AvailableMachinesResponse): AvailableMachine => ({
  id: value.id,
  bandwidth: value.bandwidth.toString(),
  cost: value.cost,
  datacenter: value.datacenter,
  purchasedAt: new Date(value.purchasedAt),
  ram: value.ram.toString(),
  storage: value.storage.toString(),
  name: value.name,
  uptime: value.uptime,
});
