import { isAxiosError } from "axios";
import dayjs from "dayjs";

import type { UserRole } from "@/types/application";
import type { IRequestOptions } from "@/types/utils";

import { client } from "../client";
import { getUserBillingSubscription } from "../network";
import type { SubscriptionV3 } from "../network/api-response-types";
import type { NewCustomerStatusResponse, UserListItemResponse, WhoAmIResponse } from "./api-response-types";
import type { AccountSummary, ContactSalesQuery, User, UserListItem, WhoAmI } from "./api-types";
import { mapNewConsumerStatusDTO, mapUserListItemDTO, mapWhoAmIUserDTO } from "./mapping";

/**
 * Retrieves information about the currently authenticated user.
 *
 * @param cookies The cookies to include in the request.
 *
 * @returns A promise that resolves when the request is complete.
 */
export const whoAmI = async (cookies?: string): Promise<WhoAmI | null> => {
  try {
    const endpoint = "/user/whoami";
    const response = await client.get<WhoAmIResponse>(endpoint, {
      headers: { Cookie: cookies },
    });

    return mapWhoAmIUserDTO(response.data);
  } catch (error) {
    console.error("Failed to retrieve user information.", error);

    if (isAxiosError(error)) {
      return null;
    }
    throw error;
  }
};

/**
 * Logs out the user by sending a POST request to the "/user/logout" endpoint.
 * @returns A promise that resolves when the logout request is complete.
 */
export const logout = async () => {
  const endpoint = "/auth/logout";
  await client.post(endpoint);
};

/**
 * Retrieves the account summary for the user.
 * @param option - Optional request options.
 * @returns A Promise that resolves to an AccountSummary object or null if the user has no billing subscription.
 */
export const getAccountSummary = async (option?: IRequestOptions): Promise<AccountSummary | null> => {
  const subscription = await getUserBillingSubscription(option);

  if (subscription == null) {
    return null;
  }

  return {
    totalProtocolsHosted: subscription.tier.nodeCount,
    totalAmount: subscription.payment.amount,
    lastPayment: {
      amount: subscription.tier.price,
      date: new Date(subscription.updatedAt),
    },
    nextBill: {
      amount: subscription.tier.price,
      date: dayjs(subscription.updatedAt).add(1, "month").toDate(),
    },
  };
};

/**
 * Retrieves the new consumer status from the user-node API.
 * @param option - Optional request options.
 * @returns A Promise that resolves to an array of NewCustomerStatusResponse objects.
 */
export const getNewConsumerStatus = async (option?: IRequestOptions) => {
  const endpoint = "/node/customer/status";
  const response = await client.get<NewCustomerStatusResponse[]>(endpoint, option?.requestConfig);

  return response.data.map(mapNewConsumerStatusDTO);
};

/**
 * Retrieves user information by their ID.
 * @param userId - The ID of the user to retrieve.
 * @returns A Promise that resolves to the user information (WhoAmI) if found, or null if not found.
 * @throws Any error that occurs during the API request.
 */
export const getUserById = async (userId: string, option?: IRequestOptions): Promise<WhoAmI | null> => {
  try {
    const endpoint = `/user/${userId}`;
    const response = await client.get<WhoAmIResponse>(endpoint, option?.requestConfig);

    return mapWhoAmIUserDTO(response.data);
  } catch (error) {
    if (isAxiosError(error) && error.response?.status === 404) {
      return null;
    }
    throw error;
  }
};

/**
 * Retrieves a list of users.
 *
 * @param option - Optional request options.
 * @returns A promise that resolves to an array of UserListItem objects.
 */
export const getUserList = async (option?: IRequestOptions): Promise<UserListItem[]> => {
  const endpoint = "/user/list";
  const response = await client.get<UserListItemResponse[]>(endpoint, option?.requestConfig);

  return response.data.map(mapUserListItemDTO);
};

/**
 * Updates the role of a user.
 *
 * @param user - The user object to update.
 * @param newRole - The new role for the user.
 * @param option - Optional request options.
 */
export const updateUserRole = async (user: User, newRole: UserRole, option?: IRequestOptions) => {
  const endpoint = `/user/change/role`;
  const body = { userId: user.id, role: newRole };

  await client.patch(endpoint, body, option?.requestConfig);
};

/**
 * Retrieves the subscriptions for a given user.
 * @param user - The user object.
 * @param option - Optional request options.
 * @returns An array of subscriptions.
 */
export const getUserSubscriptions = async (user: User, option?: IRequestOptions) => {
  const endpoint = `/admin/users/${user.id}/subscriptions`;
  const response = await client.get<SubscriptionV3[]>(endpoint, option?.requestConfig);

  return response.data;
};

export const postQuery = async (data: ContactSalesQuery, option?: IRequestOptions) => {
  const endpoint = "/user/store/query";
  await client.post(endpoint, data, option?.requestConfig);
};
