import type { AxiosError } from "axios";

export interface ServerApiError {
  error: string;
  message: string;
  statusCode: number;
}

/**
 * Checks if the provided error object is a ServerApiError.
 * @param error - The error object to check.
 * @returns A boolean value indicating whether the error is a ServerApiError.
 */
export const isServerApiError = (error: unknown): error is ServerApiError => {
  if (error == null || typeof error !== "object") {
    return false;
  }

  return "error" in error && "message" in error;
};

/**
 * Converts an AxiosError into a generic Error object.
 * If the AxiosError contains a response with server API error data, the error message from the response is used.
 * Otherwise, a generic "An unknown error occurred" message is used.
 * @param error - The AxiosError object to convert.
 * @returns The converted Error object.
 */
export const getErrorFromAxiosError = (error: AxiosError): Error => {
  let err: Error;

  if (error.response == null) {
    err = new Error(error.message);
    Error.captureStackTrace(err, getErrorFromAxiosError);
    return err;
  }

  if (isServerApiError(error.response.data)) {
    err = new Error(error.response.data.message);
    Error.captureStackTrace(err, getErrorFromAxiosError);
    return err;
  }

  err = new Error("An unknown error occurred");
  Error.captureStackTrace(err, getErrorFromAxiosError);
  return err;
};
