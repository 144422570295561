import type { IRequestOptions, UnixSeconds } from "@/types/utils";

import { client } from "../client";
import type { ActiveNodesStatsApiResponse, ApplicationStatsResponse, DeploymentStatsApiResponse } from "./api-response-type";
import type { ActiveNodeStat, ApplicationStats, DeploymentStat } from "./api-types";
import { mapActiveNodesStats, mapDeploymentStats } from "./mapping";

/**
 * Retrieves the application statistics.
 * @returns A promise that resolves to an object containing the application statistics.
 */
export const getApplicationStats = async (option?: IRequestOptions): Promise<ApplicationStats> => {
  const endpoint = "/stats";
  const response = await client.get<ApplicationStatsResponse>(endpoint, option?.requestConfig);
  return { ...response.data, totalCost: { value: response.data.totalCost, currency: "USD" } };
};

/**
 * Retrieves deployment statistics for a node from a specific time.
 * @param from - The starting time in Unix seconds.
 * @param option - Optional request options.
 * @returns A promise that resolves to the deployment statistics.
 */
export const getNodeDeploymentStats = async (from: UnixSeconds, option?: IRequestOptions): Promise<DeploymentStat> => {
  const endpoint = "/stats/nodes/deployed";
  const response = await client.get<DeploymentStatsApiResponse>(endpoint, {
    ...option?.requestConfig,
    params: { from },
  });
  return mapDeploymentStats(response.data);
};

/**
 * Retrieves the statistics for active nodes.
 * @param from The starting timestamp in Unix seconds.
 * @param option Optional request options.
 * @returns A promise that resolves to the statistics for active nodes.
 */
export const getActiveNodesStats = async (from: UnixSeconds, option?: IRequestOptions): Promise<ActiveNodeStat> => {
  const endpoint = "/stats/nodes/active";
  const response = await client.get<ActiveNodesStatsApiResponse>(endpoint, {
    ...option?.requestConfig,
    params: { from },
  });
  return mapActiveNodesStats(response.data);
};
