import dayjs from "dayjs";

import type { ActiveNodesStatsApiResponse, DeploymentStatsApiResponse } from "./api-response-type";
import type { ActiveNodeStat, DeploymentStat } from "./api-types";

export const mapDeploymentStats = (response: DeploymentStatsApiResponse): DeploymentStat => ({
  total: response.total,
  deployments: response.deployments.map((deployment) => ({
    timestamp: dayjs.unix(deployment.timestamp).toDate(),
    count: deployment.count,
  })),
});

export const mapActiveNodesStats = (response: ActiveNodesStatsApiResponse): ActiveNodeStat => ({
  total: response.total,
  nodes: response.activeNodes.map((activeNode) => ({
    timestamp: dayjs.unix(activeNode.timestamp).toDate(),
    count: activeNode.count,
  })),
});
